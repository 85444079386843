import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import {Helmet} from "react-helmet";
import Slider from "react-slick";
import { FaXTwitter } from "react-icons/fa6"
import { QuoteButton } from "../QuoteButton";

export const Blogdetail=()=>{
  const [blogdetails,setblogdetails]=useState(null);
  const banner = blogdetails?.banner;
const category = blogdetails?.category;
const category_name = blogdetails?.category_name;
const description = blogdetails?.description;
const formatted_created_at = blogdetails?.formatted_created_at;
const meta_description = blogdetails?.meta_description;
const meta_keywords = blogdetails?.meta_keywords;
const meta_title = blogdetails?.meta_title;
const title = blogdetails?.title;

  let { blogdetail } = useParams();


  const getBlogDetail=async()=>{
    try{
      const res=await axios.get(`https://shop.digitize4life.com/api/v2/blogs/details/${blogdetail}`)
      setblogdetails(res.data.data)
    }catch(err){
      console.log(err)
    }
  }
  const [blogs,setBlogs]=useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [uniqueCategories, setUniqueCategories] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState('');




  const GetBlog=async(url)=>{
    try{
     const resblog=await axios.get(url)
     setBlogs(resblog.data.data)
    }catch(err){
      console.log(err);
    }
  }
  useEffect(()=>{
    getBlogDetail()
    GetBlog('https://shop.digitize4life.com/api/v2/blogs');
  },[blogdetail])

  useEffect(() => {
    if (blogs && blogs.length > 0) {
      const categories = new Set(blogs.map(blog => blog.category.category_name));
      setUniqueCategories(categories);
    }
  }, [blogs]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          dots: false,
          autoplay: true,
          autoplaySpeed: 500
        }
      }
    ]
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
    return(
        <>
        <QuoteButton/>
        <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
        <meta name="keywords" content={meta_keywords}/>
     </Helmet>
       
        <section className="page-banner bgs-cover text-white pt-65 pb-40" id="page-banner" style={{backgroundImage: 'url(../../../images/Aboutispagecover.webp)'}}>
<div className="container">
  <div className="banner-inner mt-65">
    <h1 className="page-title wow fadeInUp delay-0-2s" style={{color:'#000', fontSize: 'xx-large'}}>Blog View</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb wow fadeInUp delay-0-4s">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Blog View</li>
      </ol>
    </nav>
  </div>
</div>
</section>
       {/* Blog Page Area start */}
<section className="blog-details-area py-60">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-xl-4 col-lg-4 order-lg-1 order-2">
        <div className="blog-sidebar rmb-75">
          <div className="widget widget-search wow fadeInUp delay-0-2s">
            <h4 className="widget-title">Search</h4>
            <form className="default-search-form" onSubmit={(e)=>e.preventDefault()}>
              <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearchChange}  required />
              <button type="submit" className="searchbutton far fa-search" />
            </form>
          </div>
          <div className="widget widget-category wow fadeInUp delay-0-4s">
            <h4 className="widget-title">Category</h4>
            <ul>
            {[...uniqueCategories].map((categoryName, index) => (
            <li key={index}>
              <button className={selectedCategory===categoryName?'colorbluew':''} onClick={() => setSelectedCategory(categoryName)} style={{background:"transparent"}}>
                {categoryName}
              </button>
            </li>
          ))}
            </ul>
          </div>
          <div className="widget widget-recent-post wow fadeInUp delay-0-2s">
            <h4 className="widget-title">Recent Blogs</h4>
            <ul>
              {blogs && blogs.filter(blog => {
        return (!selectedCategory || blog.category_name === selectedCategory) &&
            (!searchQuery || blog.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }).slice(0,9).map(({banner,id,category_name,slug,title})=>{
                return(
                  <li key={id+category_name}>
                  <div className="image">
                    <img src={banner} alt="Post" />
                  </div>
                  <div className="content">
                    <h6><Link to={`/blog/${slug}`}>{title}</Link></h6>
                  </div>
                </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-8 order-lg-2 order-1">
        <div className="blog-details-content">
          <div className="image wow fadeInUp delay-0-2s">
            <img src={banner} alt="Blog Details" />
          </div>
          <div className="content blogdetailheight">
            <ul className="blog-meta">
              <li>
                <div className="author">
                  <p className="name m-2">{category_name}</p>
                </div>
              </li>
              <li><i className="far fa-calendar-alt" />{formatted_created_at}</li>
            </ul>
            <h4 className="blog-title">{title}</h4>
            <div dangerouslySetInnerHTML={{__html:description}} style={{color:'#000'}}/>
      
           
            <ul className="tag-share pb-15">
              <li>
                <h6>Share blog :</h6>
                <div className="social-style-two">
                  <Link to="https://www.facebook.com/digitize4lifellc/" target="_blank"><i className="fab fa-facebook-f" /></Link>
                  <Link to="https://twitter.com/Digitize4lif" target="_blank"><FaXTwitter/></Link>
                  <Link to="https://www.linkedin.com/company/digitize4lifellc" target="_blank"><i className="fab fa-linkedin-in" /></Link>
                  <Link to="https://www.instagram.com/digitize4lifellc/" target="_blank"><i className="fab fa-instagram" /></Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
    <h3 className="blog-subtitle text-center mt-50 mb-30">Related Blogs</h3>
        <div className="related-news-slider mb-60">
        <Slider {...settings} className="newslick">
        {blogs && blogs.filter(blog => {
        return (!selectedCategory || blog.category_name === selectedCategory) &&
            (!searchQuery || blog.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }).map(({banner,id,slug,formatted_created_at,title,category_name})=>{
                return(
             <div className="col-4">
                   <div className="blog-item style-two wow fadeInUp delay-0-2s p-2" key={title+id}>
                  <div className="image">
                    <img src={banner} alt="Blog" />
                  </div>
                  <div className="content">
                    <div className="author">
                    <Link to={`/blogs/${category_name}`} className="name">{category_name}</Link>
                    </div>
                    <span className="date"><i className="far fa-calendar-alt" /> <a href="#">{formatted_created_at}</a></span>
                    <h5><Link to={`/blog/${slug}`}>{title}</Link></h5>
                    <Link to={`/blog/${slug}`} className="read-more">Read More <i className="far fa-long-arrow-right" /></Link>
                  </div>
                </div>
             </div>
                )
              })}
          </Slider>
        </div>
    </div>
  </div>
</section>
{/* Blog Page Area end */}
<section className="cta-area bgc-gradient">
    <div className="row">
      <div className="col-xl-4">
        <div className="cta-left-image rel z-1 wow fadeInLeft delay-0-4s">
          <img src="../images/Home/apparel-and-footwear.webp" alt="CTA Left" />
          <div className="circle-shapes white-shape no-animation">
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 align-self-center">
        <div className="cta-content text-white py-55 wow fadeInUp delay-0-2s">
          <div className="section-title mb-35">
            <span className="sub-title mb-10">Contact With Us</span>
            <h2>Ready with a design idea? Look no further!</h2>
          </div>
          <Link to="https://login.digitize4life.com/register.asp" className="theme-btn">Start Your Projects <i className="far fa-long-arrow-right" /></Link>
        </div>
      </div>
      <div className="col-xl-4">
        <div className="cta-right-image rel z-1 wow fadeInRight delay-0-2s">
          <img src="../images/Home/girl-holding-red-patch-cap.webp" alt="CTA Right" />
          <div className="circle-shapes white-shape no-animation">
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
        </>
    )
}