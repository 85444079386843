import { Link } from "react-router-dom"
import { Banner } from "../Hero/Banner"
import { CTAArea } from "../Landing/CTAArea"
import { Pricing } from "../Landing/Pricing"
import {Helmet} from "react-helmet";
import Slider from "react-slick";
import axios from "axios"
import { CircleProgress } from "../Landing/CircleProgress"
import { useEffect, useState } from "react"
import $ from 'jquery';
import 'jquery.appear';
import { helmetJsonLdProp } from "react-schemaorg";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { QuoteButton } from "../QuoteButton";

export const ServicesPage=()=>{
  const [name,setname]=useState("")
  const [email,setemail]=useState("")
  const [phone,setphone]=useState("")
  const [subject,setsubject]=useState('Vector Conversion')
  const [message,setmessage]=useState("")
  const [largePic,setLargePic]=useState(null);
  const [open, setOpen] = useState(false);

   const onOpenModal = () => setOpen(true);
   const onCloseModal = () => setOpen(false);
   const [open1, setOpen1] = useState(false);

   const onOpenModal1 = () => setOpen1(true);
   const onCloseModal1 = () => setOpen1(false);

  const ContactMe=async(e)=>{
    e.preventDefault();
    try{
      const res=await axios.post('https://shop.digitize4life.com/api/contact/post',{name,email,phone,subject,message},{
        headers:{
          Accept: 'application/json'
        }
      })
      console.log('name',name,'email',email,'phone',phone,'subject',subject,'message',message,'res',res.data)
      if(res.data.status==='success'){
        alert(res.data.message)
        setphone("")
        setemail("")
        setsubject('Select a service')
        setname("")
        setmessage("")
      }
    }catch(err){
      console.log(err);
    }
  }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true, // Add centerMode for centering slides
          centerPadding: '15px' // Adjust padding as needed
        }
      }
    ]

  };

  useEffect(() => {
    if ($.fn.circleProgress) {
      var progress11 = $('.one.circle-progress-inner');
      progress11.appear(function () {
        progress11.circleProgress({
          value: 0.76,
          size: 100,
          thickness: 7,
          fill: { gradient: ["#ff7e5f", "#feb47b"] },
          lineCap: 'square',
          startAngle: -Math.PI / 4 * 2,
          animation: { duration: 2000 },
        }).on('circle-animation-progress', function (event, progress) {
          $(this).find('h3').html(Math.round(83 * progress) + '<span>%</span>');
        });
      });
    }

    if ($.fn.circleProgress) {
      var progress12 = $('.two.circle-progress-inner');
      progress12.appear(function () {
        progress12.circleProgress({
          value: 0.85,
          size: 100,
          thickness: 7,
          fill: { gradient: ["#ff7e5f", "#feb47b"] },
          lineCap: 'square',
          startAngle: -Math.PI / 4 * 2,
          animation: { duration: 2000 },
        }).on('circle-animation-progress', function (event, progress) {
          $(this).find('h3').html(Math.round(94 * progress) + '<span>%</span>');
        });
      });
    }

    if ($.fn.circleProgress) {
      var progress13 = $('.three.circle-progress-inner');
      progress13.appear(function () {
        progress13.circleProgress({
          value: 0.8,
          size: 100,
          thickness: 7,
          fill: { gradient: ["#ff7e5f", "#feb47b"] },
          lineCap: 'square',
          startAngle: -Math.PI / 4 * 2,
          animation: { duration: 2000 },
        }).on('circle-animation-progress', function (event, progress) {
          $(this).find('h3').html(Math.round(90 * progress) + '<span>%</span>');
        });
      });
    }
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  const handleModalClose = (event) => {
    if (event.target.classList.contains('modalContainer')) {
        setLargePic(null);
    }
};

useEffect(() => {
  document.addEventListener('click', handleModalClose);

  return () => {
      document.removeEventListener('click', handleModalClose);
  };
}, []);
  const [portfoliodata,sePortfoliodata]=useState();
  const [category, setCategory] = useState('All');
  const [visibleImages, setVisibleImages] = useState(12); // State to manage the number of visible images

  const handleLoadMore = () => {
    setVisibleImages(prevVisibleImages => prevVisibleImages + 12);
  };


  const filteredPortfolio = category === 'All' ? portfoliodata : portfoliodata.filter(item => item.name === category);

   const PortfolioRes=async()=>{
     try{
      const res=await axios.get('https://shop.digitize4life.com/api/v2/portfolio');
      sePortfoliodata(res.data.portfolio);
     }catch(err){
       console.log('err',err);
     }
   }
   useEffect(()=>{
     PortfolioRes()
   },[])
    return(
        <>
        <QuoteButton/>
         <Helmet>
        <title>Expert Embroidery Digitizing | Vector Conversion | Custom Patches Services | DIGITIZE4LIFE</title>
        <meta name="description" content="Explore our comprehensive services at DIGITIZE4LIFE – Your one-stop solution for
top-notch Embroidery Digitizing and Vector Conversion. From Graphic Line Art to 3D/Puff Digitizing, we bring
your designs to life with precision and creativity." />
        <meta name="keywords" content=" Embroidery digitizing service, vector conversion, graphic line art, color separation, cap digitizing,
flat digitizing, applique digitizing, 3D/puff digitizing, chenille digitizing."/>
      <script type="application/ld+json">
        {JSON.stringify(
      helmetJsonLdProp({ 
          "@context": "http://schema.org",
          "@type": "AggregateRating",
          "ratingValue": "5",
          "reviewCount": "218"
      })
    )}
    </script>
     </Helmet>
        <Banner title="Services" image="images/ServicesCover.webp"/>
     <div>
  {/* Why Choose Area start */}
<div className="service-page-why-choose py-40 rpt-10 rel z-1">
  <div className="container">
    <div className="row large-gap align-items-center justify-content-between">
      <div className="col-lg-6 order-lg-1 order-2">
        <div className="why-choose-services-content rmb-65 wow fadeInUp delay-0-2s">
          <div className="section-title mb-20">
            <span className="sub-title mb-15">Why Choose Us</span>
            <h2>We're Your Trusted Partner in USA for Embroidery Digitizing</h2>
          </div>
          <p>At DIGITIZE4LIFE, we go beyond traditional digitizing and vector conversion. Our diverse range
of services includes:</p>
          <div className="row pt-25">
            <div className="col-sm-4 col-6">
              <div className="circle-progress-item">
              <CircleProgress value={0.5} maxValue={50000} />
                <h5>Embroidery Digitizing</h5>
              </div>
            </div>
            <div className="col-sm-4 col-6">
              <div className="circle-progress-item">
                <CircleProgress value={0.58} maxValue={58000} />
                <h5>Vector Conversion</h5>
              </div>
            </div>
            <div className="col-sm-4 col-6">
              <div className="circle-progress-item">
              <CircleProgress value={0.53} maxValue={53000} />
                <h5>Custom Patches</h5>
              </div>
            </div>
          </div>
          <div className="about-btns">
            <Link to="/about" className="theme-btn mt-20">Learn More <i className="far fa-long-arrow-right" /></Link>
            <button  className="about-video-play1 mt-20" style={{backgroundColor:"transparent"}} onClick={onOpenModal1}><i className="fas fa-play" /> <span>How IT Works</span></button>
          </div>
        </div>
      </div>
      <div className="col-lg-6 order-lg-2 order-1">
        <div className="why-choose-services-images wow fadeInLeft delay-0-2s">
          <img className="w-100" src="images/women-with-embroidery-machine.webp" alt="Our embroidery digitizing service ensures your designs are brought to life with unmatched
precision and quality." title="High-Quality Embroidery Digitizing" style={{borderRadius:"25px"}}/>
          <img className="image-two wow fadeInRight delay-0-6s" src="images/variety-of-custom-patches.webp" alt="Elevate your graphics with our vector conversion service – from intricate line art to vibrant color
separations." title="Professional Vector Conversion"/>
          <img className="image-three wow fadeInDown delay-0-8s" src="images/left-chest-D4L-logo-Mockup.webp" alt="Experience the depth and texture of 3D/Puff Digitizing, adding a new dimension to your
embroidered designs.
" title="3D/Puff Digitizing Mastery"/>
          <div className="circle-shapes">
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<Modal open={open1} onClose={onCloseModal1} center>
  <video id="about-videohome" src="images/Aboutpagevideo.mp4" alt="" title="24/7 Customer Support" style={{width:'100%',height:'100%'}}  autoPlay controls
></video>
      </Modal>
{/* Why Choose Area end */}
<section className="what-we-provide bgc-lighter pt-40 pb-40" style={{backgroundImage: 'url(images/background/what-we-provide-cover-image.png)'}}>
  <div className="container">
    <div className="section-title text-center mb-20 wow fadeInUp delay-0-2s">
      <span className="sub-title mb-10">What We Provide</span>
      <h2>Variety of Digitizing Embroidery and Custom Patches</h2>
      <div className="col-lg-12 mx-auto">
        <div className="tab-btns-one nav d-flex nav-pills wow fadeInRight delay-0-2s align-items-center justify-content-between">
          <button className="nav-link active mx-auto" data-bs-toggle="pill" data-bs-target="#project-tab-one">
          <img src="images/Home/service boxes icons for home and services page/embroidery-digitizing.avif" alt="Digitizing Embroidery" width={50} height={50}/>
            <span className="title">Digitizing Embroidery</span>
          </button>
          <button className="nav-link mx-auto" data-bs-toggle="pill" data-bs-target="#project-tab-two">
          <img src="images/Home/service boxes icons for home and services page/custom-patch.avif" alt="Custom Patches" width={50} height={50}/>
            <span className="title">Custom Patches</span>
          </button>
          <button className="nav-link mx-auto" data-bs-toggle="pill" data-bs-target="#project-tab-three">
          <img src="images/Home/service boxes icons for home and services page/vector-conversion.avif" alt="Custom Patches" width={50} height={50}/>
            <span className="title mx-2">Vector Conversion</span>
          </button>
        </div>
      </div>
    </div>
    <div className="row align-items-center justify-content-between">
      <div className="col-lg-12">
        <div className="project-tab-content rel wow fadeInLeft delay-0-2s">
          <div className="tab-content">
            <div className="tab-pane fade show active" id="project-tab-one">
            <Slider className="servicespagetabsli" {...settings}>
            <div className="project-item1">
                  <div className="content">
                   <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/vector-conversion.avif" alt="Project" /></div>
                    <div className="col-md-8"> <div className="text">
                     <div className="d-flex justify-content-between">
                     <h4><Link to="/services/Vector-Conversion">Vector Conversion</Link></h4><Link className="plus" to="/services/Vector-Conversion">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Transform your images into scalable, high-quality vectors with our expert vector conversion services.
Whether it's for logos, illustrations, or graphics, we ensure precision and clarity in every detail, making your visuals
stand out.
</span>
                    </div></div>
                   </div>
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"> <img src="images/Home/service boxes icons for home and services page/embroidery-digitizing.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                     <div className="d-flex justify-content-between">
                     <h4><Link to="/services/Flat-Embroidery-Digitizing">Flat Embroidery Digitizing</Link></h4><Link className="plus" to="/services/Flat-Embroidery-Digitizing">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Elevate your designs with the artistry of flat embroidery digitizing. Our skilled team translates your artwork
into flawless stitch patterns, capturing intricate details and creating stunning embroidered pieces that leave a lasting
impression.
</span>
                    </div> 
                    </div>
                   </div>
               
                  </div>
                </div>
                <div className="project-item1">  
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/graphic-designing.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                     <div className="d-flex justify-content-between">
                     <h4><Link to="/services/Graphic-Designing">Graphic Designing</Link></h4><Link className="plus" to="/services/Graphic-Designing">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Unleash the power of captivating visuals through our graphic designing services. From branding materials
to promotional content, our creative team crafts engaging designs that communicate your message effectively and
leave a memorable impact.
</span>
                    </div>
                    </div>
                   </div>
                 
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"> <img src="images/Home/service boxes icons for home and services page/applique.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                     <div className="d-flex justify-content-between">
                     <h4><Link to="/services/Applique-Embroidery-Digitizing">Applique Embroidery Digitizing</Link></h4><Link className="plus" to="/services/Applique-Embroidery-Digitizing">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Add texture and dimension to your embroidery with our applique digitizing services. We skillfully
incorporate fabric elements into your designs, creating unique and visually appealing embroidery that brings a tactile
richness to your projects.
</span>
                    </div>
                    </div>
                   </div>
                  
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/line-art.avif" alt="Project" /> </div>
                    <div className="col-md-8">
                    <div className="text">
                     <div className="d-flex justify-content-between">
                     <h4><Link to="/services/Line-Art">Line Art</Link></h4><Link className="plus" to="/services/Line-Art">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Experience the beauty of simplicity with our line art. We specialize in translating
intricate line drawings into stunning embroidery, ensuring that each stitch is a perfect representation of your artistic
vision.
</span>
                    </div>
                    </div>
                   </div>
                  
                  </div>
                </div>
                <div className="project-item1"> 
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/3D-Puff.avif" alt="Project" /> </div>
                    <div className="col-md-8">
                    <div className="text">
                     <div className="d-flex justify-content-between">
                     <h4><Link to="/services/3D-Puff-Embroidery-Digitizing">3D Puff Embroidery Digitizing</Link></h4><Link className="plus" to="/services/3D-Puff-Embroidery-Digitizing">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Give your embroidery a dynamic and textured look with our 3D puff embroidery digitizing. We skillfully
create designs that pop off the fabric, adding depth and visual interest to your embroidered logos, lettering, and
graphics.
</span>
                    </div>
                    </div>
                   </div>    
                  </div>
                </div>
                <div className="project-item1"> 
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/logo-designing.png" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                     <div className="d-flex justify-content-between">
                     <h4><Link to="/services/Logo-Designing">Logo Designing</Link></h4><Link className="plus" to="/services/Logo-Designing">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Make a lasting impression with a professionally designed logo. Our logo designing services combine
creativity with strategic thinking, resulting in unique and impactful logos that represent your brand identity with
distinction.
</span>
                    </div>
                    </div>
                   </div>
                  </div>
                </div>
                <div className="project-item1">
                  
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/Chenille.png" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                     <div className="d-flex justify-content-between">
                     <h4><Link to="/services/Chenille-Embroidery-Digitizing">Chenille Embroidery Digitizing</Link></h4><Link className="plus" to="/services/Chenille-Embroidery-Digitizing">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Experience the soft and velvety texture of chenille embroidery digitizing. Our experts bring a touch of
luxury to your designs, creating plush and intricate patterns that enhance the visual and tactile appeal of your
embroidered items.
</span>
                    </div>
                    </div>
                   </div>         
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"> <img src="images/Home/service boxes icons for home and services page/Sequin.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                     <div className="d-flex justify-content-between">
                     <h4><Link to="/services/Sequin-Embroidery-Digitizing">Sequin Embroidery Digitizing</Link></h4><Link className="plus" to="/services/Sequin-Embroidery-Digitizing">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Bring a touch of glamour to your embroidery with our sequin digitizing services. We expertly integrate
sequins into your designs, adding sparkle and shine to your embroidered apparel, accessories, and more.
</span>
                    </div>
                    </div>
                   </div>        
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/Photo-Editing.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                     <div className="d-flex justify-content-between">
                     <h4><Link to="/services/Photo-Editing">Photo Editing</Link></h4><Link className="plus" to="/services/Photo-Editing">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Perfect your visuals with our professional photo editing services. From retouching to color correction, we
enhance the visual appeal of your images, ensuring that your photos convey the desired message with clarity and
impact.

</span>
                    </div>
                    </div>
                   </div>
                  </div>
                </div>
            </Slider>
            </div>
            <div className="tab-pane fade" id="project-tab-two">
              <Slider className="servicespagetabsli" {...settings}>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"> <img src="images/Home/service boxes icons for home and services page/custom-patch.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                    <div className="d-flex justify-content-between">
                     <h4><Link to="/custom-patches/Custom-Patches">Custom Patches</Link></h4><Link className="plus" to="/custom-patches/Custom-Patches">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span> Crafting personalized patches that tell your unique story is our specialty. Our custom patches are
meticulously designed to reflect your brand, commemorate special events, or add a touch of individuality to your
garments and accessories.
</span>
                    </div>
                    </div>
                   </div>
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/Embroidered-Patches.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                    <div className="d-flex justify-content-between">
                     <h4><Link to="/custom-patches/Embroidered-Patches">Embroidered Patches</Link></h4><Link className="plus" to="/custom-patches/Embroidered-Patches">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Experience the timeless elegance of embroidered patches with our precision embroidery services. We
transform your logos, emblems, and designs into finely stitched patches, ensuring durability and a distinct, professional
look.
</span>
                    </div>
                    </div>
                   </div>    
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/Chenille Patches.png" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                    <div className="d-flex justify-content-between">
                     <h4><Link to="/custom-patches/Chenille-Patches">Chenille Patches</Link></h4><Link className="plus" to="/custom-patches/Chenille-Patches">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span> Indulge in the soft and luxurious texture of chenille patches. Our expert craftsmanship brings a classic and
tactile quality to your designs, making chenille patches a standout choice for adding a touch of sophistication to your
apparel.
</span>
                    </div>
                    </div>
                   </div>
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/Woven-patch.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                    <div className="d-flex justify-content-between">
                     <h4><Link to="/custom-patches/Woven-Patches">Woven Patches</Link></h4><Link className="plus" to="/custom-patches/Woven-Patches">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span> For a sleek and detailed look, opt for our woven patches. Using advanced weaving techniques, we create
patches with intricate designs and sharp details, providing a polished and refined finish to your garments.
</span>
                    </div>
                    </div>
                   </div>
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"><img src="images/Home/service boxes icons for home and services page/pvc.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                    <div className="d-flex justify-content-between">
                     <h4><Link to="/custom-patches/PVC-Patches">PVC Patches</Link></h4><Link className="plus" to="/custom-patches/PVC-Patches">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span> Explore a modern and durable patch option with our PVC patches. Resistant to wear, tear, and the
elements, PVC patches are perfect for outdoor gear, uniforms, and accessories, offering a contemporary aesthetic with
long-lasting quality.
</span>
                    </div>
                    </div>
                   </div>
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"> <img src="images/Home/service boxes icons for home and services page/Printed-patch.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                    <div className="d-flex justify-content-between">
                     <h4><Link to="/custom-patches/Printed-Patches">Printed Patches</Link></h4><Link className="plus" to="/custom-patches/Printed-Patches">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Infuse vibrant colors and intricate details into your patches with our printed patch services. We use
high-quality printing techniques to reproduce your designs faithfully, resulting in patches that capture every nuance
and shade.
</span>
                    </div>
                    </div>
                   </div>
                  </div>
                </div>
                <div className="project-item1">
                  <div className="content">
                  <div className="row">
                    <div className="col-md-4"> <img src="images/Home/service boxes icons for home and services page/Leather-patch.avif" alt="Project" /></div>
                    <div className="col-md-8">
                    <div className="text">
                    <div className="d-flex justify-content-between">
                     <h4><Link to="/custom-patches/Leather-Patches">Leather Patches</Link></h4><Link className="plus" to="/custom-patches/Leather-Patches">
                      <i className="far fa-plus" />
                    </Link>
                     </div>
                      <span>Elevate your products with the rich and timeless appeal of leather patches. Our expert craftsmanship
ensures that each leather patch is a testament to quality and sophistication, making it a perfect choice for premium
branding on apparel and accessories.
</span>
                    </div>
                    </div>
                   </div>   
                  </div>
                </div>
              </Slider>
            </div>
            <div className="tab-pane fade" id="project-tab-three">
            <section className="contact-form-area mt-40 mb-40 wow fadeInUp delay-0-2s">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <form id="contactForm"  className="contact-form z-1 rel" name="contactForm" onSubmit={ContactMe}>
            <div className="section-title text-center mb-40">
              <span className="sub-title">Request a quotation for Vector Conversion</span>
              <h2 style={{textTransform:"inherit"}}>Simply submit your details below, and we'll get in touch with you as soon as possible to get the designs and process further.</h2>
            </div>
            <div className="row mt-25">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="name"><i className="far fa-user mx-3" /></label>
                  <input type="text" id="name" name="name" className="form-control" placeholder="Full Name" value={name} onChange={(e)=>setname(e.target.value)} required data-error="Please enter your name" />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="email"><i className="far fa-envelope mx-3" /></label>
                  <input type="email" id="email" name="email" className="form-control" placeholder="Email Address" value={email} onChange={(e)=>setemail(e.target.value)} required data-error="Please enter your name" />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="phone"><i className="far fa-phone mx-3" /></label>
                  <input type="text" id="phone" name="phone" className="form-control" value={phone} onChange={(e)=>setphone(e.target.value)}  placeholder="Phone Number" />
                </div>
              </div>
              <div className="col-sm-6 mb-30">
                <div className="form-group">
                  <label htmlFor="subject"><i className="far fa-question-circle mx-3" /></label>
                  <select id="subject" value={subject} onChange={(e)=>setsubject(e.target.value)}>
                    <option value="default">Select a service</option>
                    <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                    <option value="Vector Conversion" selected>Vector Conversion</option>
                    <option value="Custom Patches">Custom Patches</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="message"><i className="far fa-highlighter mx-3" /></label>
                  <textarea name="message" id="message" className="form-control" value={message} onChange={(e)=>setmessage(e.target.value)} rows={4} placeholder="Write your Message" required data-error="Please enter your Message" />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="tac-wrap mb-30">
                  <input type="checkbox" id="tac" name="tac" defaultValue="tac" />
                  <label htmlFor="tac">I agree that my data is collected and stored.</label>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group mb-0">
                  <button type="submit" className="theme-btn">Send Message <i className="fas fa-arrow-right" /></button>
                  <div id="msgSubmit" className="hidden" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
            </div>
          </div>
          <div className="circle-shapes" style={{paddingTop:'30%'}}>
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div></section>
 {/* Services Four Area start */}
 <section className="project-page-area rel z-1 pt-40 pb-40">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-8 col-lg-9">
        <div className="section-title text-center mb-40 wow fadeInUp delay-0-2s">
          <span className="sub-title mb-10">Embroidery Digitizing Services</span>
          <h2>See through our past piece of arts. We are providing amazing Embroidery Digitizing, Vector Conversion and
Custom Patches services since 2015.
</h2>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid">
    <ul className="project-filter mb-50 wow fadeInUp delay-0-4s">
    <li className="current" onClick={() => setCategory('All')}>Show All</li>
      {portfoliodata && [...new Set(portfoliodata.map(item => item.name))].map((name, index) => (
    <li className="current" key={index} onClick={() => setCategory(name)}>
        {name}
    </li>
))}
    </ul>
    <div className="row project-two-active project-twoheight">
    {filteredPortfolio && filteredPortfolio.slice(0, visibleImages).map(({id,name,image})=>{
        return(
          <div className="col-xl-3 col-lg-4 col-sm-6 item logoo 3dprint" key={id+name} onClickCapture={onOpenModal} onClick={()=>setLargePic(image)}>
          <div className="project-item-two">
            <img src={image} alt={name} />
          </div>
        </div>
        )
      })}
    </div>
    {filteredPortfolio && filteredPortfolio.length > visibleImages && ( // Render the "Load More" button conditionally
        <div className="text-center mt-10">
          <button onClick={handleLoadMore} className="theme-btn">Load More</button>
        </div>
      )}
 {largePic && <Modal open={open} onClose={onCloseModal} center>
          <div className="largerpicmodelcontainer">
          <img src={largePic} alt={largePic} />
          </div>
      </Modal>
}
  </div>
</section>
{/* Services Four Area end */}


  {/* FAQ Area start */}
  <div className="faq-counter-area bgc-lighter pt-40 pb-50 rel z-1">
    <div className="container">
      <div className="row large-gap align-items-center">
        <div className="col-lg-6">
          <div className="faq-content rmb-65 wow fadeInUp delay-0-2s">
            <div className="section-title mb-35">
              <span className="sub-title mb-15">Have Any Question?</span>
              <h2>Frequently Ask Questions</h2>
            </div>
            <div className="accordion" id="faq-accordion">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                    What Services Are You Getting?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                  <div className="accordion-body">
                    <p>Explore a wide array of premium services tailored to meet your printing needs. From meticulous embroidery digitizing
to crafting custom patches and precise vector conversion, we offer a comprehensive suite of services to elevate your
brand.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                  Why Come to Our Digitize4Life?
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#faq-accordion">
                  <div className="accordion-body">
                    <p>Choose Printco for a seamless blend of expertise, innovation, and customer-centric solutions. We take pride in our
commitment to delivering top-notch printing services, ensuring your projects are executed with precision, creativity,
and unparalleled quality.
</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                  How much do our services cost?
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                  <div className="accordion-body">
                    <p>Our pricing is designed to offer value without compromising on quality. Experience cost-effective solutions for
embroidery digitizing, vector conversion, and custom patches. Get a quote today and discover the affordability of
excellence.
</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                  Learn About Our Team Member(s)?
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                  <div className="accordion-body">
                    <p>Meet our dedicated team of experts, passionate about bringing your vision to life. With years of experience, creativity,
and technical proficiency, our team members are the driving force behind our success. Get to know the individuals who
make Digitizing4Life a trusted name in the Embroidery Digitizing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="faq-image wow fadeInUp delay-0-4s">
            <img src="images/faq image.webp" alt="Our cap digitizing service ensures that your logos and designs are flawlessly translated onto
caps, showcasing expertise and attention to detail." title="Cap Digitizing Excellence"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* FAQ Area end */}
</div>

        <Pricing/>
        <CTAArea/>
        </>
    )
}