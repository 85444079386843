import { Link } from "react-router-dom"
import { CTAArea } from "../Landing/CTAArea"
import {Helmet} from "react-helmet";
import { useRef, useState } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { QuoteButton } from "../QuoteButton";
import { Testimonials } from "../Landing/Testimonials"


export const AboutPage=()=>{
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        const video = document.getElementById('about-video');

        if (video.paused) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
    };

     // Function to handle 'ended' event
     const handleVideoEnded = () => {
      setIsPlaying(false); // Update state when the video ends
  };
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

    return(
        <>
          <Helmet>
        <title>About Digitize4life - Embroidery Digitizing Excellence</title>
        <meta name="description" content="Discover the essence of Digitize4life, a leading embroidery digitizing company committed
to exceeding customer expectations. Explore our customer-oriented approach and experience unparalleled
excellence in transforming artwork. Thrive for excellence with us!" />
        <meta name="keywords" content="Embroidery digitizing, Digitize4life, Customer-oriented digitizing, Artwork digitization, Embroidery
services, Excellence in digitizing."/>
     </Helmet>
     <QuoteButton/>
     <section className="page-banner bgs-cover text-white pt-65 pb-40" id="page-banner" style={{backgroundImage: 'url(images/Aboutispagecover.webp)'}}>
<div className="container">
  <div className="banner-inner mt-65">
    <h1 className="page-title wow fadeInUp delay-0-2s" style={{color:'#000', fontSize: 'xxx-large'}}>About Us</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb wow fadeInUp delay-0-4s">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">About Us</li>
      </ol>
    </nav>
  </div>
</div>
</section>
      {/* About Area start */}

      <div>
  <div className="about-page-area py-40 rpt-120 rel z-1">
    <div className="container">
      <div className="row large-gap justify-content-between">
        <div className="col-lg-6">
          <div className="about-page-content rmb-65 wow fadeInUp delay-0-2s">
            <div className="section-title mb-20">
              <span className="sub-title mb-15">ABOUT US</span>
              <h2>Elevating Artwork, Thriving on Excellence</h2>
            </div>
            <p>Welcome to Digitize4life, your trusted partner in the world of embroidery digitizing. As a
prominent player in the industry and a proud member of a US-based conglomerate, we take pride in setting
the benchmark for excellence and customer satisfaction.<br/>
At Digitize4life, our primary goal is to deliver embroidery digitizing services that go beyond the ordinary. We
are not just a service provider; we are architects of your artistic vision. Our commitment is to bring out the
best for our customers, establishing long-term relationships based on trust and quality.
</p>
            <div className="about-btns pt-5">
              <Link to="/about" className="theme-btn mt-20">Learn More <i className="far fa-long-arrow-right" /></Link>
              <button onClick={onOpenModal} className="video-play1 mt-20"><i className="fas fa-play" /></button>
              <button onClick={onOpenModal} className="theme-btn mt-20 bg-white text-dark"><span>How IT Works</span></button>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mission-vision-part">
            <div className="mission-vision-item wow fadeInUp delay-0-4s">
              <div className="progress-content one">
                <i className="flaticon-target" />
              </div>
              <div className="content">
                <h4>Our Mission</h4>
                <p>Dedicated to delivering unparalleled quality and innovation in embroidery digitizing, vector conversion, and custom patches.</p>
              </div>
            </div>
            <div className="mission-vision-item wow fadeInUp delay-0-6s">
              <div className="progress-content two">
                <i className="flaticon-mission" />
              </div>
              <div className="content">
                <h4>Our Vision</h4>
                <p>Striving to become the premier destination for top-tier digitizing and patch solutions, setting new standards of excellence in the
industry.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* About Area end */}
  <Modal open={open} onClose={onCloseModal} center>
  <video id="about-videohome" src="images/Aboutpagevideo.mp4" alt="About" title="about video" style={{width:'100%',height:'100%'}}  autoPlay controls
></video>
      </Modal>
  {/* Fact Counter start */}
  <div className="video-fact-counter bgc-lighter" style={{backgroundImage: 'url(images/about/about-counter-bg.webp)'}}>
    <div className="container">
    <div className="about-video about-page-video">
            <video ref={videoRef} id="about-video" src="images/Aboutpagevideo.mp4" alt="About" style={{ width: '100%', height: '100%' }} onClick={togglePlay} onEnded={handleVideoEnded}></video>
            {!isPlaying && (
                <div className="video-play2" onClick={togglePlay}>
                    <i className="fas fa-play"></i>
                </div>
            )}
        </div>
    </div>
    <div className="circle-shapes">
      <div className="shape-inner">
        <span className="dot-one" />
        <span className="dot-two" />
        <span className="dot-three" />
        <span className="dot-four" />
        <span className="dot-five" />
      </div>
    </div>
  </div>
  {/* Fact Counter end */}
  {/* Why Choose Area start */}
  <div className="why-choose-three pt-40 pb-40 rel z-1 bgc-black text-white">
    <div className="container">
      <div className="services-inner ">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title mb-15">Why Choose Digitize4life?</span>
              <h2>Customer-Centric Approach</h2>
              <p>We are deeply committed to our customers. Your satisfaction is not just a goal; it's our main
focus. We understand your unique needs and prioritize your requirements to deliver exceptional results.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div className="service-item style-three wow fadeInUp delay-0-2s" style={{height:'22rem'}}>
              <div className="icon">
                <i className="flaticon-network" /> 
              </div>
              <h5>Excellence as Standard</h5>
              <p>From intricate designs to complex projects, we strive for perfection in every detail.</p>
              <div className="bg-image" style={{backgroundImage: 'url(images/services/service-bg-three.webp)'}} />
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="service-item style-three wow fadeInUp delay-0-4s" style={{height:'22rem'}}>
              <div className="icon">
                <i className="flaticon-air-plane" />
              </div>
              <h5>Building Relationships</h5>
              <p>We value our customers as the cornerstone of our success.</p>
              <div className="bg-image" style={{backgroundImage: 'url(images/services/service-bg-three.webp)'}} />
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="service-item style-three wow fadeInUp delay-0-6s" style={{height:'22rem'}}>
              <div className="icon">
                <i className="flaticon-award" />
              </div>
              <h5>Fulfilling Requirements</h5>
              <p>Your vision becomes our mission, and we deliver with precision.</p>
              <div className="bg-image" style={{backgroundImage: 'url(images/services/service-bg-three.webp)'}} />
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="service-item style-three wow fadeInUp delay-0-8s" style={{height:'22rem'}}>
              <div className="icon">
                <i className="flaticon-technical-support" />
              </div>
              <h5>Ultimate Customer Experience</h5>
              <p>Feel the seamless process, your satisfaction is our ultimate goal.</p>
              <div className="bg-image" style={{backgroundImage: 'url(images/services/service-bg-three.webp)'}} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="circle-shapes">
      <div className="shape-inner">
        <span className="dot-one" />
        <span className="dot-two" />
        <span className="dot-three" />
        <span className="dot-four" />
        <span className="dot-five" />
      </div>
    </div>
  </div>
  {/* Why Choose Area end */}
</div>

        <Testimonials/>
        <CTAArea/>
        
        </>
    )
}