import { Link } from "react-router-dom"
import ServiceDetaildata from "../Data/ServiceDetaildata";
import {Helmet} from "react-helmet";
import { useEffect, useState } from "react";
import "../../css/style.css";
import "../../css/flaticon.min.css";
import "../../css/fontawesome.min.css";


import { helmetJsonLdProp } from "react-schemaorg";
import Pdffile from '../../images/D4L SizeChart.pdf';
import { useParams } from 'react-router-dom';
import { QuoteButton } from "../QuoteButton";

export const Servicedetail=()=>{
  const pathname = window.location.pathname;
  const { name } = useParams();
// Remove the leading '/' and decode URI components
const decodedPathname = decodeURIComponent(pathname.substring(0));
const lastSlashIndex = decodedPathname.lastIndexOf('/');
const serviceName = decodedPathname.substring(lastSlashIndex + 1);
const [categoryName,setCategoryName]=useState(name);
const [filteredData, setFilteredData] = useState([]);
const [itemtitle,setitemtitle]=useState('');


 useEffect(() => {
   // Filter ServiceDetaildata based on the updated pathname
   const filtered = ServiceDetaildata.filter(item => item.slug === name);
   setFilteredData(filtered);
   if (filtered.length > 0) {
    setitemtitle(filtered[0].title);
  }
 }, [name]);

 const handleDownloadPDF = () => {
  // Replace '/D4L SizeChart.pdf' with the actual path to your PDF file
  const filePath = Pdffile;
  const link = document.createElement('a');
  link.href = filePath;
  link.setAttribute('download', 'D4L_SizeChart.pdf');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};



     
    return(
        <>
        <QuoteButton/>
        <section className="page-banner bgs-cover text-white pt-65 pb-45" id="page-banner" style={{backgroundImage: 'url(../../../images/ServicesCover.webp)'}}>
  <div className="container">
    <div className="banner-inner mt-65">
      <h1 className="page-title wow fadeInUp delay-0-2s" style={{color:'#000', fontSize: 'xxx-large'}}>{itemtitle}</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb wow fadeInUp delay-0-4s">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active">{itemtitle}</li>
        </ol>
      </nav>
    </div>
  </div>
</section>
        <div>
  {/* Services Area start */}
  <section className="service-details-area pt-60 rpt-120 pb-40">
    <div className="container">
      <div className="row large-gap">
        <div className="col-lg-4 order-lg-1 order-2">
          <div className="service-sidebar rmb-75">
            <div className="widget widget-category wow fadeInUp delay-0-2s">
              <h2 className="widget-title before-circle" style={{ fontSize: 'xx-large'}}>Service Category</h2>
              <ul>
                <li><Link to="/services/embroidery-digitizing" className={serviceName === 'embroidery-digitizing' ? 'blue-background' : ''} onClick={()=>setCategoryName('embroidery digitizing')}>Embroidery Digitizing</Link></li>
                <li><Link to="/services/flat-embroidery-digitizing" className={serviceName === 'flat-embroidery-digitizing' ? 'blue-background' : ''} onClick={()=>setCategoryName('flat embroidery digitizing')}>Flat Embroidery Digitizing</Link></li>
                <li><Link to="/services/applique-embroidery-digitizing" className={serviceName === 'applique-embroidery-digitizing' ? 'blue-background' : ''} onClick={()=>setCategoryName('applique embroidery digitizing')}>Applique Embroidery Digitizing</Link></li>
                <li><Link to="/services/3D-puff-embroidery-digitizing" className={serviceName === '3D-puff-embroidery-digitizing' ? 'blue-background' : ''} onClick={()=>setCategoryName('3D puff embroidery digitizing')}>3D Puff Embroidery Digitizing</Link></li>
                <li><Link to="/services/chenille-embroidery-digitizing" className={serviceName === 'chenille-embroidery-digitizing' ? 'blue-background' : ''} onClick={()=>setCategoryName('chenille embroidery digitizing')}>Chenille Embroidery Digitizing</Link></li>
                <li><Link to="/services/sequin-embroidery-digitizing" className={serviceName === 'sequin-embroidery-digitizing' ? 'blue-background' : ''} onClick={()=>setCategoryName('sequin embroidery digitizing')}>Sequin Embroidery Digitizing</Link></li>
                <li><Link to="/services/vector-conversion" className={serviceName === 'vector-conversion' ? 'blue-background' : ''} onClick={()=>setCategoryName('vector conversion')}>Vector Conversion</Link></li> 
                <li><Link to="/services/graphic-designing" className={serviceName === 'graphic-designing' ? 'blue-background' : ''} onClick={()=>setCategoryName('graphic designing')}>Graphic Designing</Link></li>
                <li><Link to="/services/logo-designing" className={serviceName === 'logo-designing' ? 'blue-background' : ''} onClick={()=>setCategoryName('logo designing')}>Logo Designing</Link></li>
                <li><Link to="/services/photo-editing" className={serviceName === 'photo-editing' ? 'blue-background' : ''} onClick={()=>setCategoryName('photo editing')}>Photo Editing</Link></li>
                <li><Link to="/services/line-art" className={serviceName === 'line-art' ? 'blue-background' : ''} onClick={()=>setCategoryName('line art')}>Line Art</Link></li>
              </ul>
            </div>
            <div className="widget widget-download wow fadeInUp delay-0-2s">
              <h4 className="widget-title before-circle">Download Size Chart</h4>
              <div className="download-btns">
                <a href="#" className="theme-btn style-three mb-15" onClick={handleDownloadPDF}>Download pdf <i className="far fa-file-pdf" /></a>
              </div>
            </div>
            <div className="widget widget-cta wow fadeInUp delay-0-2s">
              <span className="h5">Need any Digitizing or Vector Conversion Service</span>
              <h3>or Custom Patches?</h3>
              <h6>You are at the right place for all of these.</h6>
              <Link to="/contact" className="theme-btn mb-40">Talk With Us <i className="far fa-long-arrow-right" /></Link><br />
              <img src="../../../images/Servicedetailpages/servicedetailpagesidebarimage.webp" alt="CTA" />
            </div>
          </div>
        </div>
        <div className="col-lg-8 order-lg-2 order-1">
          {filteredData.map(({title,id,metatitle,metades,metakeyword,AltTitle,AltDescription,Content,content1,content2,content3,choosetitle,choose,chooseboxcenter,q1,a1,q2,a2,q3,a3,q4,a4,q5,a5,image})=>{
            return(
              <div key={id}>
                      <div className="service-details-content">
                     <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metades} />
        <meta name="keywords" content={metakeyword}/>
        <script type="application/ld+json">
    {JSON.stringify(
      helmetJsonLdProp({
        "@context": "http://schema.org",
  "@type": "Service",
  "serviceType": `${title}`,
  "provider": {
    "@type": "LocalBusiness",
    "name": "Digitizing 4 Life"
  },
  "areaServed": "Texas, United States",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": `${id}`,
      "item": {
        "@id": "https://www.digitize4life.com/embroidery-digitizing",
        "name": `${title}`
      }
    }]
      })
    )}
  </script>
     </Helmet>
             <div className="image mb-35 wow fadeInUp delay-0-2s">
               <img src={image} alt={AltDescription} title={AltTitle} />
             </div>
             <h3 className="before-circle">{title}</h3>
             <p>{Content}</p>
             <div dangerouslySetInnerHTML={{ __html: content1 }} />
             {choosetitle!=="" && <> <h4 className="before-circle mt-35">{choosetitle}</h4>
             <div className="row large-gap work-process-two-wrap justify-content-center mt-35 mb-20 rel z-1">
              {choose && choose.map((item)=>{
                return(
                  <div className="col-md-3 col-sm-6">
                  <div className="work-process-two-item wow fadeInUp delay-0-2s">
                    <div className="icon">
                      <i className={item.icon} />
                    </div>
                    <span className="step-number">{item.title}</span>
                    <h5>{item.para}</h5>
                  </div>
                </div>
                )
              })}
             </div>
             
             <p>{chooseboxcenter}</p></>}
             {content2!=="" && <h4>{content2}</h4>}
             {content3!=="" && <div dangerouslySetInnerHTML={{ __html: content3 }} />}
             <div className="accordion pt-10" id="faq-accordion">
               <div className="accordion-item">
                 <h2 className="accordion-header">
                   <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                     {q1}
                   </button>
                 </h2>
                 <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                   <div className="accordion-body">
                     <p>{a1}</p>
                   </div>
                 </div>
               </div>
               <div className="accordion-item">
                 <h2 className="accordion-header">
                   <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                     {q2}
                   </button>
                 </h2>
                 <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#faq-accordion">
                   <div className="accordion-body">
                     <p>{a2}</p>
                   </div>
                 </div>
               </div>
               <div className="accordion-item">
                 <h2 className="accordion-header">
                   <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                    {q3}
                   </button>
                 </h2>
                 <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                   <div className="accordion-body">
                     <p>{a3}</p>
                   </div>
                 </div>
               </div>
               <div className="accordion-item">
                 <h2 className="accordion-header">
                   <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                     {q4}
                   </button>
                 </h2>
                 <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                   <div className="accordion-body">
                     <p>{a4}</p>
                   </div>
                 </div>
               </div>
             </div>
           </div>
              </div>
            )
          })}
         
        </div>
      </div>
    </div>
  </section>
  {/* Services Area end */}

</div>

{/*CTA Area start */}
<section className="cta-area bgc-gradient">
    <div className="row">
      <div className="col-xl-4">
        <div className="cta-left-image rel z-1 wow fadeInLeft delay-0-4s">
          <img src="../../../images/Home/apparel-and-footwear.webp" alt="CTA Left" />
          <div className="circle-shapes white-shape no-animation">
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 align-self-center">
        <div className="cta-content text-white py-55 wow fadeInUp delay-0-2s">
          <div className="section-title mb-35">
            <span className="sub-title mb-10">Contact With Us</span>
            <h2>Ready with a design idea? Look no further!</h2>
          </div>
          <Link to="/portfolio" className="theme-btn">Start Your Projects <i className="far fa-long-arrow-right" /></Link>
        </div>
      </div>
      <div className="col-xl-4">
        <div className="cta-right-image rel z-1 wow fadeInRight delay-0-2s">
          <img src="../../../images/Home/girl-holding-red-patch-cap.webp" alt="CTA Right" />
          <div className="circle-shapes white-shape no-animation">
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*CTA Area end */}


        </>
    )
}