import { Link } from "react-router-dom"
import { Banner } from "../Hero/Banner"
import { CTAArea } from "../Landing/CTAArea"
import {Helmet} from "react-helmet";
import { useRef, useState } from "react";
import Modal from "react-responsive-modal";
import axios from "axios";
import { QuoteButton } from "../QuoteButton";

export const FAQ=()=>{
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [name,setname]=useState("")
    const [email,setemail]=useState("")
    const [phone,setphone]=useState("")
    const [subject, setsubject] = useState(localStorage.getItem('services') || 'select a service');
    const [message,setmessage]=useState("")

    const togglePlay = () => {
        const video = document.getElementById('about-video');

        if (video.paused) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
    };

     // Function to handle 'ended' event
     const handleVideoEnded = () => {
      setIsPlaying(false); // Update state when the video ends
  };
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const ContactMe=async(e)=>{
    e.preventDefault();
    try{
      const res=await axios.post('https://shop.digitize4life.com/api/v2/contact/post',{name,email,phone,subject,message},{
        headers:{
          Accept: 'application/json'
        }
      })
      console.log('name',name,'email',email,'phone',phone,'subject',subject,'message',message,'res',res.data)
      if(res.data.status==='success'){
        alert(res.data.message)
        setphone("")
        setemail("")
        setsubject('Select a service')
        setname("")
        setmessage("")
        localStorage.removeItem('services')
      }
    }catch(err){
      console.log(err);
    }
  }
    return(
        <>
        <QuoteButton/>
          <Helmet>
        <title>FAQs | Digitize4Life - Clear Answers to Your Queries</title>
        <meta name="description" content="Find comprehensive answers to your queries about digitization services, file formats,
quality checks, billing, and more at Digitize4Life FAQs. Clear your doubts and embark on a seamless
experience with our services." />
        <meta name="keywords" content="Digitize4Life FAQs, Embroidery digitizing queries, File formats, Quality checks, Billing process,
Multiple logo sizes, Payment options"/>
     </Helmet>
        <Banner title="Faqs" image="images/banner.webp"/>
       <div>
  {/* Faq Area start */}
  <section className="faq-page-area rel z-1 pt-60 pb-40">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-9">
          <div className="section-title text-center mb-50 wow fadeInUp delay-0-2s">
            <span className="sub-title mb-10">FREQUENTLY ASKED QUESTIONS</span>
            <h2>Have Any Question For Embroidery Digitizing, Vector Conversion or Custom Patches? Let us
know!</h2>
          </div>
        </div>
      </div>
      <div className="accordion" id="faq-accordion">
        <div className="row">
          <div className="col-lg-6 mb-15 wow fadeInUp delay-0-2s">
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                What fabric/garments do you digitize for? What about special embroideries?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>Digitize4Life excels in digitizing virtually anything: left and front chest, caps, visors, beanies, jacket backs,
gloves, patches, sleeves, towels, golf and other bags, as well as various fabrics like pique, jersey, fleece,
polyester, leather, and terry. We specialize in handling special embroideries such as foam, applique, redwork,
and sequins.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                What file formats can I send and receive back?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>We accept a wide range of formats like DST, EXP, JEF, PES, and more. We send digitized formats in DST
unless otherwise instructed. Additionally, we can digitize from AI, CDR, EPS, GIF, JPG, PDF, PNG, PSD, TIF,
and other formats. Our graphic designers can clean up artwork from scanned garments, hand drawings, or
photographs.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                What type of quality checks do you perform?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>We conduct thorough quality checks, including an initial review of artwork and design requirements. The
head digitizer oversees technical aspects like stitch density, sequencing, trims, and color changes. The
sew-out is meticulously checked, and corrections are made until perfection. The service team performs a
final recheck to ensure no details are overlooked.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                How many art files can I submit when entering an order?
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>While most customers submit one or two art files, you can submit up to eight files for a single order.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive">
                Is there any charge to open an account on your Website?
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>No, opening an account is entirely free. You only pay for the actual digitizing, and we offer free estimates</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-15 wow fadeInUp delay-0-4s">
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix">
                How do you bill, and how are we supposed to pay?
                </button>
              </h2>
              <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>We email an invoice for each order, and you can securely make payments through a PayPal™ link provided
in the email. You don't need to share credit card information with us.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSeven">
                Why am I receiving 2 options of my logo? Will I be charged for both options?
                </button>
              </h2>
              <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>Second options are provided for clarity or when there's uncertainty about your requirements. There is never a
charge for the provision of second options.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseEight">
                Do you provide any other services?
                </button>
              </h2>
              <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>While our primary focus is digitizing and vector art services, our experienced team can offer informal advice
on various aspects of the embroidery and printing value chain, if needed.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseNine">
                Want multiple sizes of one logo or have to embroider lots of names; will I be charged full price for each?
                </button>
              </h2>
              <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>We price the logo based on the largest size, with smaller ones incurring an extra charge of $10. Minor size
differences may not result in additional charges, and special discounts are offered for names.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTen">
                Can I provide my credit card information directly to you for payment processing?
                </button>
              </h2>
              <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>For secure payments, call 817-857-1034 or email billing@digitize4life.com for assistance. Our designated
accounts person is available to help with your queries and payments</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseEleven">
                  How To Improve Printing Knowledge?
                </button>
              </h2>
              <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam aperiam eaquey quae abillo inventore</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Faq Area end */}
  {/* Faq Video Area start */}
  <div className="faq-page-video wow fadeInUp delay-0-2s">
    <div className="container">
    <div className="about-video about-page-video">
            <video ref={videoRef} id="about-video" src="images/Aboutpagevideo.mp4" alt="About" style={{ width: '100%', height: '100%' }} onClick={togglePlay} onEnded={handleVideoEnded}></video>
            {!isPlaying && (
                <div className="video-play2" onClick={togglePlay}>
                    <i className="fas fa-play"></i>
                </div>
            )}
        </div>
    </div>
  </div>
  <Modal open={open} onClose={onCloseModal} center>
  <video id="about-videohome" src="images/Aboutpagevideo.mp4" alt="About" title="about video" style={{width:'100%',height:'100%'}}  autoPlay controls
></video>
      </Modal>
  {/* Faq Video Area end */}
  {/* Printing Solutions Area start */}
  {/* <section className="printing-solutions-area pt-120 pb-130">
    <div className="container">
      <div className="row large-gap align-items-center">
      <div className="col-lg-6">
        <div className="hero-images wow fadeInLeft delay-0-2s">
          <img className="w-100" src="images/Home/Cover Image center.jpg" alt="Discover our craftsmanship with this intricate custom embroidery patch that captures every
detail of your design" title="Exquisite Custom Embroidery Patch" style={{borderRadius:"25px"}}/>
          <img className="image-one wow fadeInRight delay-0-6s" src="images/Home/custom-patches-on-leather-jacket.webp" alt="Elevate your graphics with precision! Our vector conversion services ensure sharp, high-quality
images for all your design needs." title="Precision Vector Conversion"/>
          <div className="circle-shapes">
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
        <div className="col-lg-6">
          <div className="printing-solutions-content wow fadeInRight delay-0-2s">
            <div className="section-title mb-30">
              <span className="sub-title mb-5">Digitize 4 Life</span>
              <h2>Your trusted Embroidery Digitizing Partner</h2>
            </div>
            <p>With more than 25 years of experience in embroidery digitizing services, we stand as a USA-based top embroidery digitizing company that takes pride in delivering pixel perfect and top-notch services.</p>
            <ul className="list-style-one pt-5">
              <li>Embroidery Digitizing</li>
              <li>Custom Patches</li>
              <li>Vector Conversion</li>
            </ul>
            <Link to="/about" className="theme-btn mt-35">Learn More Us <i className="far fa-long-arrow-right" /></Link>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  {/* Printing Solutions Area end */}
  {/* Contact Form Start */}
  <section className="faq-contact-form-area mb-130 wow fadeInUp delay-0-2s">
    <div className="container">
      <form id="faq-contact-form" className="faq-contact-form z-1 rel" name="faq-contact-form" onSubmit={ContactMe}>
        <div className="section-title text-center mb-40">
          <span className="sub-title">Contact Us</span>
          <h2>Send Us Your Message</h2>
        </div>
        <div className="row mt-25">
          <div className="col-lg-3">
            <div className="form-group">
              <input type="text" id="full-name" name="full-name" className="form-control"  placeholder="Full Name" value={name} onChange={(e)=>setname(e.target.value)} required />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <input type="email" id="email-address" name="email" className="form-control"  placeholder="Email Address" value={email} onChange={(e)=>setemail(e.target.value)} required />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <input type="text" id="phone" name="phone" className="form-control"  value={phone} onChange={(e)=>setphone(e.target.value)}  placeholder="Phone Number" required />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
            <select id="subject" value={subject} onChange={(e)=>setsubject(e.target.value)}>
                    <option value="default" selected>Select a service</option>
                    <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                    <option value="Vector Conversion">Vector Conversion</option>
                    <option value="Custom Patches">Custom Patches</option>
                  </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <textarea name="message" id="message" className="form-control" value={message} onChange={(e)=>setmessage(e.target.value)} rows={4} placeholder="Write your Message" required ={""} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group text-center mb-0">
              <button type="submit" className="theme-btn">Send Your Message <i className="fas fa-arrow-right" /></button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
  {/* Contact Form End */}
</div>

        <CTAArea/>
        </>
    )
}